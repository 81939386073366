import $vuetify from 'vuetify/src/locale/de.ts'

export default {
	$vuetify,	
	"app": {
		"manage": "Verwalten",
		"new_user_setup": "Neue Benutzereinrichtung",
		"access_manager": "Zugriffsmanager",
		"primary_key_manager": "Primärschlüsselmanager",
		"your_pk_must_be_blank": "Ihr PK muss leer sein (damit der Server Ihnen einen neuen ausgibt) oder mit einem Bindestrich und einer Zahl enden (g.g., \"-0\")",	
		"to_manage_your_pk": "Um Ihren PK zu verwalten, geben Sie dessen PIN ein",
		"anonymous_activation": "Anonyme Aktivierung",
		"primary_key_management": "Primärschlüsselverwaltung",
		"primary_key_tqrtoken_services": "Dies ist Ihr neuer Primärschlüssel für den Zugriff auf alle TQRToken-Dienste:",
		"primary_key_data_on_device": "Dies ist Ihr Primärschlüssel für alle Ihre Daten auf diesem Gerät:",
		"to_secure_your_primary_key": "Um Ihren Primärschlüssel im Falle eines Missgeschicks zu sichern, kopieren Sie den Schlüssel in den Abschnitt {b_notes} Ihrer eigenen Kontaktkarte in der Kontakt-App auf Ihrem Gerät.",
		"notes": "Notes",
		"to_share_data_between_devices": "Um Daten zwischen Geräten auszutauschen, überschreiben Sie hier Ihren Primärschlüssel mit einem Primärschlüssel von einem anderen Gerät:",
		"to_enable_multiple_devices": "Um mehrere Geräte zu aktivieren, fügen Sie einen weiteren PK ein",
		"we_suggest_a_pin": "Wir empfehlen eine PIN, die Sie woanders verwenden",
		"to_secure_your_pk": "Um Ihre PK (optional) zu sichern, geben Sie eine 4-6-stellige PIN ein"	
	},
	"newuser": {
		"anonymous_activation": "Anonyme Aktivierung",
		"anywhere_anyone_anytime": "Überall, Für jeden, Jederzeit",
		"no_app": "Keine App",
		"no_tracking":"Keine Verfolgung",
		"your_tensor_profile": "Dein Tensor Profil"
	},
	"confirmdialog": {
		"cancel": "Abbrechen",
		"ok": "OK"
	},
	"prompt": {
		"done": "Erledigt",
		"cancel": "Abbrechen"
	},
	"aegis2": {
		"positive_number_only": "Nur positive Zahl.",
		"required": "Erforderlich.",
		"add": "Hinzufügen",
		"edit": "Bearbeiten",
		"a_connection": " sie eine Verbindung",
		"new_campaign": "Neue Kampagne",
		"new_reward": "Neue Belohnung",
		"root_connection": "Root Verbindung",
		"your_base_of_operations": "Ihre Operationsbasis",
		"typically_this_is_your_brand": "Normalerweise ist dies Ihr Markenname",
		"branch_connection": "Zweigverbindung",
		"delimit_organization_parts_with": "Organisationsteile mit @ abgrenzen",
		"minimum_tokens": "Mindestanzahl an Tokens",
		"connections": "Verbindungen",
		"add_connection": "Verbindung Hinzufügen",
		"register_account": "Account Registrieren"
	},
	"rewards": {
		"earn_tokens": "Verdienen Tokens",
		"consumer_scans_qr_to_earn": "Der Verbraucher scannt diesen QR-Code, um Token zu {b_earn}.",
		"earn": "verdienen",
		"qr_earn_url": "QR's Verdienen URL",
		"goes_to_the_same_place": "Geht an die gleiche Stelle wie der QR-Code",
		"copy_earn_url": "Verdienen URL Kopieren",
		"rewards_view": "Belohnungsansicht",
		"consumer_scans_qr_to_burn": "Der Verbraucher scannt diesen QR-Code, um Token zu {b_burn}.",
		"burn": "brennen",
		"qr_burn_url": "QR's Brennen URL",
		"copy_burn_url": "Brennen URL Kopieren",
		"earn_url": "Verdienen URL",
		"redirect_consumer_after_earning_tokens": "Verbraucher nach dem verdienen von Token umleiten",
		"burn_url": "Brennen URL",
		"redirect_consumer_after_burning_tokens": "Verbraucher nach dem brennen von Token umleiten",
		"invalid_url_keep_adding": "Ungültige URL. Fügen Sie weiterhin die erforderlichen Daten hinzu (oder vergleichen Sie sie).",
		"edit_reward_html": "Belohnungs HTML Bearbeiten",
		"reward_view_html": "Belohnungsansicht HTML",
		"enter_the_reward_menu_content": "Geben Sie den Inhalt des Prämienmenüs in HTML ein.",
		"cancel": "Abbrechen",
		"save": "Speichern"
	},
	"campaigns": {
		"campaign_events": "Kampagnenereignisse",
		"add_campaign": "KAMPAGNE HINZUFÜGEN",
		"add_a_new_campaign": "Fügen Sie eine neue Kampagne hinzu oder fügen Sie bearbeitete Daten zur ausgewählten Kampagne hinzu",	
		"welcome_to_tqr": "Willkommen bei TQR",
		"to_get_started": "Um anzufangen:",
		"click_add_campaign_button_above": "Klicken Sie oben auf die Schaltfläche {b_add_campaign}. Ihre Kampagne wird angezeigt.",
		"click_the_campaign_label": "Klicken Sie auf das {b_campaign} label oder auf die Schaltfläche {b_add_event} neben Ihrer neuen Kampagne.",
		"campaign": "Kampagne",
		"add_event": "Ereignis Hinzufügen",
		"fill_in_the_dialog": "Füllen Sie den Dialog aus und klicken Sie auf die Schaltfläche {b_ok}.",
		"ok": "OK",
		"to_see_your_events": "Um Ihre Ereignisse anzuzeigen, klicken Sie auf ein Kampagnenlabel. Wenn Sie ein Ereignis bearbeiten müssen, klicken Sie auf der Ereignisbezeichnung auf {b_edit_event}.",
		"edit_event": "Ereignis Bearbeiten",
		"click_the_add_campaign_button": "Klicken Sie auf die Schaltfläche {b_add_campaign}, um diese Willkommensnachricht zu löschen.",
		"add_a_campaign_connection": "Fügen Sie eine Kampagnenverbindung hinzu",
		"root_connection": "Root Verbindung",
		"your_base_of_operations": "Ihre Operationsbasis",
		"typically_this_is_your_brand": "Normalerweise ist dies Ihr Markenname",
		"branch_connection": "Zweigverbindung",
		"delimit_organization_parts_with": "Organisationsteile mit @ abgrenzen",
		"required": "Erforderlich.",
		"archive": "Archivieren",
		"campaign_to_archive": "Kampagne zum Archivieren",
		"if_you_archive_this_campaign": "Wenn Sie diese Kampagne archivieren, wird sie in Ihren Verbindungen nicht angezeigt. Alle mit der archivierten Kampagne verknüpften Ereignisse stehen nicht mehr zur Nutzung zur Verfügung.",
		"new_event_name": "Neuer Veranstaltungsname:"
	},
	"eventcard": {
		"event_details": "Ereignis Einzelheiten",
		"earn_qr": "Verdienen QR",
		"click_to_print_event_earn": "Klicken Sie hier, um den Ereignis Verdienen QR-Code zu drucken",
		"burn_qr": "Brennen QR",
		"click_to_print_event_burn": "Klicken Sie hier, um den Ereignis Brennen QR-Code zu drucken",
		"event_name": "Ereignis Name",
		"campaigns_event_name": "Name des Ereignisses der Kampagne",
		"event_tokens_earned": "Verdiente Ereignis Token",
		"number_of_tokens_earned": "Anzahl der mit dem Ereignis verdienten Token",
		"earn_redirect_url": "Verdienen Umleiten URL",
		"url_after_earning_tokens": "URL nach dem Sammeln von Token",
		"burn_redirect_url": "Brennen Umleiten URL",
		"url_to_burn_tokens": "URL zum Brennen von Token",
		"event_banner_url": "Ereignis Banner URL",
		"image_to_display_while_earning": "Bild, das beim VERDIENEN von Token angezeigt wird",
		"event_burn_banner_url": "Ereignis Brennen Banner URL",
		"image_to_display_while_burning": "Bild, das beim BRENNEN von Token angezeigt wird",
		"last_update": "Die Neufassung:",
		"save": "Speichern",
		"archive": "Archivieren",
		"event_to_archive": "Zu archivierendes Ereignis",
		"if_you_archive_this_event": "Wenn Sie dieses Ereignis archivieren, wird es nicht in Ihrer Ereignisliste angezeigt.",
		"archive_event": "Archivereignis",
		"adding_event_data": "Ereignisdaten werden hinzugefügt..."
	},
	"campaignevent": {
		"copy_qr_url_earn_burn": "Kopieren Sie die QR-URL, um Token zu verdienen oder zu brennen",
		"event_tokens_qr": "Ereignis Token QR",
		"qrtype": "{qrType}",
		"qrType": {
		"Burn": "Brennen",
		"Earn": "Verdienen",
		"Redirect": "Umleiten"
		}
	}
}