const {
  connectionColor,
  head,
  headingColor,
  isEmpty,
  pj,
  tokenColor,
} = require('../utils/helpers');
const { CRDs } = require('../redis/constants');
const TARGET_NAMES = {
  connection: 'connection',
  connections: 'connections',
  tokens: 'tokens', // scan
  earn: 'earn',
  burn: 'burn',
  archive: 'archive',

  get: 'get', // get Rewards View HTML
  set: 'set', // set Rewards View HTML
};
// TARGETS provides data to build consistent keys and hashes
// call field dereferences external js module
const TARGETS = {
  connection: {
    crd: CRDs.create,
    name: 'Connection',
    hash: 'connections',
    call: 'rewards',
  },
  archive: {
    crd: CRDs.archive,
    name: 'Connection',
    hash: 'connections',
    call: 'rewards',
  },
  connections: {
    crd: CRDs.scan,
    name: 'Connections',
    hash: 'connections',
    call: 'rewards',
  },

  earn: {
    crd: CRDs.earn,
    name: 'Tokens',
    hash: 'tokens',
    call: 'rewards',
  },

  burn: {
    crd: CRDs.burn,
    name: 'Tokens',
    hash: 'tokens',
    call: 'rewards',
  },

  tokens: {
    crd: CRDs.scan,
    name: 'Tokens',
    hash: 'tokens',
    call: 'rewards',
  },

  getHash: {
    crd: CRDs.get,
    name: 'Hash',
    hash: 'urls',
    call: 'rewards',
  },
  setHash: {
    crd: CRDs.set,
    name: 'Hash',
    hash: 'urls',
    call: 'rewards',
  },
  getMHash: {
    crd: CRDs.getM,
    name: 'Hash',
    hash: 'urls',
    call: 'rewards',
  },
  setMHash: {
    crd: CRDs.setM,
    name: 'Hash',
    hash: 'urls',
    call: 'rewards',
  },

  getJson: {
    crd: CRDs.get,
    name: 'Json',
    call: 'rewards',
  },
  setJson: {
    crd: CRDs.set,
    name: 'Json',
    call: 'rewards',
  },
};
let preamble, call;

const init = (params) => {
  console.log(headingColor('Call params :>> '));
  console.log(pj(params));

  preamble = params.preamble;
  call = params.call;
  console.log();
};

const connectionKey = () => {
  return {
    preamble,
    owner: call.owner || '*',
    hash: 'connections',
    id: call.id || '*',
  };
};
const baseKey = () => {
  return {
    preamble,
    owner: call.owner || '*',
    hash: call.hash,
  };
};

const tokenKey = () => {
  return {
    ...baseKey(),
    hash: `tokens`,
    id: call.id || '*',
  };
};

const keys = () => {
  return {
    createConnection: connectionKey(),
    scanConnections: connectionKey(),
    archiveConnection: connectionKey(),

    scanTokens: tokenKey(),
    earnTokens: tokenKey(),
    burnTokens: tokenKey(),

    getHash: baseKey(),
    setHash: baseKey(),
    getJson: baseKey(),
    setJson: baseKey(),
  };
};

const op = () => {
  return {
    crd: 'scan',
    intent: call.intent,
    context: {
      key: keys()[call.intent],
      value: { ...call.value },
    },
  };
};

const transform = (result) => {
  console.log(
    `===================================== Leaving testServer ============================================

${headingColor('Operation')}: 
${tokenColor(pj(op()))}

${headingColor('Produced Key result[0]:')}
${tokenColor(pj(result[0]))}

${headingColor('Produced Results:')}
${tokenColor(pj(result))}
`
  );
  //////////////////////////////////////////////////////////////////////
  const experiment = true;
  if (experiment) {
    return result; // let Redis Maps take over
  }
  //////////////////////////////////////////////////////////////////////

  if (typeof result === 'string') {
    return result;
  }

  console.log(
    connectionColor(`============================ Entering transform() ============================
`)
  );

  const regexNonce = /(tqr:\S{2}:)([\w*@\w*]*)/gi; // eslint-disable-line

  const transformResult = () => {
    // Connection example:
    // tqr:xx:1651857240675-x:connections:mpc@dbg@675@x@uhoh';
    // [0] [1][2]             [3]         [4]

    // Tokens example:
    // tqr:lo:test@date:tokens:1659059618220-0
    // [0] [1][2]       [3]    [4]

    const map = new Map(result);
    for (const [key, value] of map) {
      console.log(`${key} = ${pj(value)}`);
      const keyParts = key.split(':');
      console.log('keyParts :>> ', keyParts);
      // owner can be (connection's) pid or (usually) a nonce
      const owner = keyParts[2];
      // regex returns any string that contains @
      const header = head(key.match(regexNonce));

      // regex includes preamble: e.g., "tqr:lo:test@date"
      // we only want the nonce part
      const nonce = header.slice(header.lastIndexOf(':') + 1);
      // id can be pid, nonce, eventName, or cid
      const id = keyParts.at(-1);
      console.log('owner, nonce, and id:');
      console.table([owner, nonce, id].flat(2));
      console.log(' ');
      // v.val (can be/is) array.
      // Update the first field for any data useful related to the key
      console.log(value);
      if (value[0]) {
        value[0].owner = owner;
        value[0].nonce = key.includes('connections') ? id : nonce;
      }

      console.log(`${key} = ${pj(value)}`);
      console.log(' ');
    }
    return [...map];
  };

  const isArrayOrMap = () => {
    if (Array.isArray(result) && typeof result[0] === 'string') {
      return result;
    } else {
      return transformResult();
    }
  };

  const transformedResult = isEmpty(result) ? [] : isArrayOrMap();
  console.table(transformedResult);
  console.log(
    connectionColor(
      `============================ Leaving transform() ============================
      `
    )
  );
  return transformedResult;
};

module.exports = { init, op, transform, TARGET_NAMES, TARGETS };
