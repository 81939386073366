const {
  headingColor,
  isEmpty,
  pj,
  tokenColor,
} = require('../utils/helpers');

const { CRDs } = require('../redis/constants');
const TARGET_NAMES = {
  campaign: 'campaign',
  event: 'event',
  events: 'events',
  readEvent: 'readEvent',
  eventTokens: 'eventTokens',
  readEventTokens: 'readEventTokens',
  earn: 'earn',
  burn: 'burn',
  archive: 'archive',
  archiveEvent: 'archiveEvent',
};
// TARGETS provides data to build consistent keys and hashes
// call field dereferences external js module
// name field can be the same because the cdr field discriminates when concatenated
const TARGETS = {
  connections: {
    crd: CRDs.scan,
    name: 'Connections',
    hash: 'connections',
    call: 'campaigns',
  },
  connection: {
    crd: CRDs.create,
    name: 'Connection',
    hash: 'connections',
    call: 'campaigns',
  },
  campaign: {
    crd: CRDs.create,
    name: 'Campaign',
    hash: 'connections',
    call: 'campaigns',
  },
  archive: {
    crd: CRDs.archive,
    name: 'Campaign',
    hash: 'connections',
    call: 'campaigns',
  },
  archiveEvent: {
    crd: CRDs.archive,
    name: 'Event',
    hash: 'events',
    call: 'campaigns',
  },

  // singular event means create one
  event: {
    crd: CRDs.create,
    name: 'Event',
    hash: 'events',
    call: 'campaigns',
  },

  // plural events means scan them
  events: {
    crd: CRDs.scan,
    name: 'Events',
    hash: 'events',
    call: 'campaigns',
  },

  earn: {
    crd: CRDs.earn,
    name: 'EventTokens',
    hash: 'tokens',
    call: 'campaigns',
  },

  burn: {
    crd: CRDs.burn,
    name: 'EventTokens',
    hash: 'tokens',
    call: 'campaigns',
  },

  eventTokens: {
    crd: CRDs.scan, 
    name: 'EventTokens',
    hash: 'tokens',
    call: 'campaigns',
  },
  readEvent: {
    crd: CRDs.read, 
    name: 'Event',
    hash: 'events',
    call: 'campaigns',
  },
  readEventTokens: {
    crd: CRDs.read, 
    name: 'EventTokens',
    hash: 'tokens',
    call: 'campaigns',
  },

  getHash: {
    crd: CRDs.get,
    name: 'Hash',
    call: 'campaigns',
  },
  setHash: {
    crd: CRDs.set,
    name: 'Hash',
    call: 'campaigns',
  },

  getMHash: {
    crd: CRDs.getM,
    name: 'Hash',
    call: 'campaigns',
  },
  setMHash: {
    crd: CRDs.setM,
    name: 'Hash',
    call: 'campaigns',
  },
};
let preamble, nonce, eventName, call;
const init = (params) => {
  preamble = params.preamble;
  nonce = params.nonce;
  eventName = params.eventName;
  call = params.call;

  console.log();
};
const connectionKey = () => {
  return {
    preamble,
    owner: call.owner || '*',
    hash: 'connections',
    id: call.id || '*',
  };
};
const campaignKey = () => {
  return {
    preamble,
    owner: call.owner || '*',
    hash: 'connections',
    id: nonce,
  };
};
const baseKey = () => {
  return {
    preamble,
    owner: call.owner,
  };
};
const hashKey = () => {
  return {
    preamble,
  };
};

// tqr:xx:mpc@chrome@220@oops:events:Test Event
// [0] [1][2]                 [3]    [4]
const eventKey = () => {
  const suffix = eventName ? `:${eventName}` : '';
  // console.log('suffix :>> ', suffix);
  return {
    ...baseKey(),
    hash: `events${suffix}`,
    id: call.id,
  };
};
const tokenKey = () => {
  const prefix = eventName ? `${eventName}:` : '*:';

  return {
    ...baseKey(),
    hash: `${prefix}tokens`,
    id: call.id,
  };
};

const keys = () => {
  return {
    createCampaign: campaignKey(),
    scanConnections: connectionKey(),
    archiveCampaign: campaignKey(),

    createEvent: eventKey(),
    scanEvents: eventKey(),
    readEvent: eventKey(),
    archiveEvent: eventKey(),

    scanEventTokens: tokenKey(),
    readEventTokens: tokenKey(),
    earnEventTokens: tokenKey(),
    burnEventTokens: tokenKey(),

    getHash: hashKey(),
    setHash: hashKey(),
    getMHash: hashKey(),
    setMHash: hashKey(),
  };
};

const op = () => {
  const getValue = (value) =>
    Array.isArray(value) ? [...value] : { ...value };

  return {
    crd: 'scan',
    intent: call.intent,
    context: {
      key: keys()[call.intent],
      value: getValue(call.value),
    },
  };
};

const transform = (result) => {
  // Campaign Event Token example:
  // tqr:xx:mpc@chrome@220@oops:Test Event:tokens:1651857240675-x';
  // [0] [1][2]                 [3]        [4]    [5]
  //
  // Events example:
  // tqr:xx:mpc@chrome@220@oops:events:A Ficticious Event';
  // [0] [1][2]                 [3]    [4]
  console.log(`transform()
${headingColor('Operation')}: 
${tokenColor(pj(op()))}

${headingColor('Produced Key:')}
${tokenColor(result[0])}

${headingColor('Produced Results:')}
${tokenColor(pj(result))}
      `);

  const transformResult = () => {
    if (typeof result === 'string' || !Array.isArray(result)) {
      return result;
    }
    // Campaign Event Token example:
    // tqr:xx:mpc@chrome@220@oops:Test Event:tokens:1651857240675-x';
    // [0] [1][2]                 [3]        [4]    [5]
    //
    // Events example:
    // tqr:xx:mpc@chrome@220@oops:events:A Ficticious Event';
    // [0] [1][2]                 [3]    [4]

    const map = new Map(result);
    for (const [key, value] of map) {
      // console.log(`${key} = ${pj(value)}`);
      const keyParts = key.split(':');
      // console.log('keyParts :>> ', keyParts);

      const campaign = keyParts[2];
      const eventName = keyParts.length === 6 ? keyParts[3] : keyParts[4];
      value[0].campaign = campaign;
      value[0].eventName = eventName;

      // console.log(`${key} = ${pj(value)}`);
      // console.log(' ');
    }
    return [...map];
  };

  const isArrayOrMap = () => {
    if (Array.isArray(result) && typeof result[0] === 'string') {
      return result;
    } else {
      return transformResult();
    }
  };
  const transformedResult = isEmpty(result) ? [] : isArrayOrMap();
  console.log('transform() result:');
  console.table(transformedResult);

  return transformedResult;
};

module.exports = { init, op, transform, TARGET_NAMES, TARGETS };
