import $vuetify from 'vuetify/src/locale/zh-Hans.ts'

export default {
  $vuetify,
		"app": {
		"manage": "管理",
		"new_user_setup": "新用户设置",
		"access_manager": "使用权管理窗",
		"primary_key_manager": "PK 管理窗",
		"your_pk_must_be_blank": "您的 PK 必须为空白（以便服务器会给您一个新的 PK）或者以 “”“-” 和一个数字为结尾 (g.g., \"-0\")",
		"to_manage_your_pk": "要管理您的 PK，请提供其 PIN 码",
		"anonymous_activation": "匿名激活",
		"primary_key_management": "PK 管理",
		"primary_key_tqrtoken_services": "这是您访问所有 TQR Token 服务的新 PK：",
		"primary_key_data_on_device": "这是您在此设备上所有数据的 PK：",
		"to_secure_your_primary_key": "为了在发生意外情况时保护您的 PK，请将该 PK 复制到您在设备上的联系人应用程序中您自己的名片的 {b_notes} 部分。",
		"notes": "笔记",
		"to_share_data_between_devices": "要在您的设备之间共享数据，请使用另一个设备的 PK 覆盖这个 PK：",
		"to_enable_multiple_devices": "要启用多个设备，请粘贴另一个 PK",
		"we_suggest_a_pin": "我们建议您使用在其他地方使用的 PIN 码",
		"to_secure_your_pk": "（可选）要保护您的 PK，请输入4-6位数的 PIN 码",
		"pin_changed_to": "PIN 已换成:",
		"pin_removed": "PIN 已移除",
		"security_pin": "安全码 PIN",
		"use_only_digits": "仅使用数字（4-6个）"
  },
  "newuser": {
    "anonymous_activation": "匿名激活",
    "anywhere_anyone_anytime": "任何地方，任何人，任何时候",
    "no_app": "无应用程序",
		"no_tracking": "无追踪",
    "your_tensor_profile": "您的Tensor个人资料"
  },
  "confirmdialog": {
    "cancel": "取消",
		"ok": "OK"
  },
  "prompt": {
		"done": "完成",
    "cancel": "取消"
  },
  "aegis2": {
		"positive_number_only": "只有正数。",
		"required": "必须。",
		"add": "增加",
		"edit": "更改",
		"a_connection": "一个连接",
		"new_campaign": "新活动",
		"new_reward": "新奖励",
		"root_connection": "根支连接",
		"your_base_of_operations": "您的运营地",
		"typically_this_is_your_brand": "通常这是您的品牌名称",
		"branch_connection": "分支连接",
		"delimit_organization_parts_with": "使用 @ 分隔您的组织",
		"minimum_tokens": "最低数量的代币",
		"connections": "连接",
		"add_connection": "增加连接",
		"register_account": "注册账号",
		"reward": "奖励",
		"campaign": "活动",
		"venue": "地点",
		"type": "类型",
		"use_only_at_to_separate": "只使用 @ 来分隔名字",
		"correct_your_data": "在保存数据之前更正数据。",
		"register": "注册"
  },
  "rewards": {
    "earn_tokens": "赚取代币",
		"consumer_scans_qr_to_earn": "消费者扫描此二维码以 {b_earn} 代币。",
		"earn": "赚取",
		"qr_earn_url": "二维码的赚取网址",
		"goes_to_the_same_place": "前往与二维码相同的位置",
		"copy_earn_url": "复制赚取网址",
		"rewards_view": "奖励视图",
		"consumer_scans_qr_to_burn": "消费者扫描此二维码以 {b_burn} 代币。",
		"burn": "销毁",
		"qr_burn_url": "二维码的销毁网址",
		"copy_burn_url": "复制销毁网址",
		"earn_url": "赚取网址",
		"redirect_consumer_after_earning_tokens": "在赚取代币后将消费者重新导向",
		"burn_url": "销毁网址",
		"redirect_consumer_after_burning_tokens": "在销毁代币后将消费者重新导向",
		"invalid_url_keep_adding": "无效的网址。请继续增加（或检查）所需的数据。",
		"edit_reward_html": "编辑奖励 HTML",
		"reward_view_html": "奖励视图 HTML",
		"enter_the_reward_menu_content": "以HTML格式输入奖励菜单的内容。",
		"cancel": "取消",
		"save": "保存",
		"copied": "已复制",
		"thanks": "谢谢"
  },
  "campaigns": {
		"campaign_events": "活动和事件",
		"add_campaign": "增加活动",
		"add_a_new_campaign": "新增一个活动或将编辑后的数据添加到选定的活动中",	
		"welcome_to_tqr": "欢迎来到 TQR",
		"to_get_started": "开始:",
		"click_add_campaign_button_above": "点击上方的 {b_add_campaign} 按钮。您将看到您的活动出现。",
		"click_the_campaign_label": "点击 {b_campaign} 标签或您的新活动旁边的 {b_add_event} 按钮。",
		"campaign": "活动",
		"add_event": "增加事件",
		"fill_in_the_dialog": "填写对话框，然后点击 {b_ok} 按钮。",
		"ok": "OK",
		"to_see_your_events": "要查看您的事件，请点击活动标签。如果您需要编辑一个事件，请点击该事件标签上的 {b_edit_event}。",
		"edit_event": "编辑事件",
		"click_the_add_campaign_button": "点击 {b_add_campaign} 按钮以清除此欢迎消息。",
		"add_a_campaign_connection": "增加一个活动连接",
		"root_connection": "根支连接",
		"your_base_of_operations": "您的运营地",
		"typically_this_is_your_brand": "通常这是您的品牌名称",
		"branch_connection": "分支连接",
		"delimit_organization_parts_with": "使用 @ 分隔您的组织",
		"required": "必须。",
		"archive": "存档",
		"campaign_to_archive": "将活动存档",
		"if_you_archive_this_campaign": "如果您将此活动存档，它将不会出现在您的连接中。任何附加到存档活动的事件将不再可用。",
		"new_event_name": "新事件名称:",
		"use_only_at_to_separate": "只使用 @ 来分隔名字",
		"campaign_url": "活动网址",
		"event_tokens_qr": "事件代币二维码",
		"copy_qr_url_to": "复制二维码网址以赚取或销毁代币",
		"copied": "已复制",
		"thanks": "谢谢",
		"qrtype": "{qrType}",
		"qrType": {
			"Burn": "销毁",
			"Earn": "赚取",
			"Campaign": "活动"
		}
  },
  "eventcard": {
		"event_details": "事件详情",
		"earn_qr": "赚取二维码",
		"click_to_print_event_earn": "点击以打印事件的赚取二维码",
		"burn_qr": "销毁二维码",
		"click_to_print_event_burn": "点击以打印事件的销毁二维码",
		"event_name": "事件名称",
		"campaigns_event_name": "活动的事件名称",
		"event_tokens_earned": "赚取的事件代币",
		"number_of_tokens_earned": "事件赚取的代币数量",
		"earn_redirect_url": "赚取网址",
		"url_after_earning_tokens": "赚取代币后的网址",
		"burn_redirect_url": "销毁网址",
		"url_to_burn_tokens": "销毁代币后的网址",
		"event_banner_url": "事件横幅的网址",
		"image_to_display_while_earning": "赚取代币时显示的图像",
		"event_burn_banner_url": "事件销毁横幅的网址",
		"image_to_display_while_burning": "销毁代币时显示的图像",
		"last_update": "最后的更新:",
		"save": "保存",
		"archive": "存档",
		"event_to_archive": "将事件存档",
		"if_you_archive_this_event": "如果您将此事件存档，它将不会出现在您的事件列表中。",
		"archive_event": "存档事件",
		"adding_event_data": "添加事件数据...",
		"defer_earn": "延迟赚取",
		"defer_earn_token_after_redirect": "网址后延迟赚取代币"
  },
  "campaignevent": {
		"copy_qr_url_earn_burn": "复制二维码网址以赚取或销毁代币",
		"event_tokens_qr": "事件代币二维码",
		"qrtype": "{qrType}",
		"copied": "已复制",
		"thanks": "谢谢",
		"qrType": {
			"Burn": "销毁",
			"Earn": "赚取",
			"Redirect": "重新导向"
		}
  },
	"view": {
		"tokens_to_burn_for": "为此奖励而要销毁的代币",
		"counting_earnings": "计算收益中...",
		"last_earned": "最后赚取:",
		"burn_all": "全部销毁",
		"burn_some": "部分销毁",
		"register": "注册",
		"burn_tokens": "销毁代币",
		"earn_tokens": "赚取代币",
		"show_this_to_the_provider": "将此显示给商家以获得信用 (或必要时取消)。",
		"you_are_about_to_burn": "您即将销毁所有的 <strong>{thisTokens}</strong> 代币。",
		"you_have_no_tokens": "您没有可销毁的代币",
		"you_have_available_tokens": "您有 <strong>{availableTokens}</strong> 可销毁的代币。",
		"we_are_sorry": "很抱歉。您目前还没有可销毁的代币。",
		"token_burn": "代币销毁",
		"tokens_to_burn": "要销毁的代币",
		"burn_more": "销毁更多",
		"burn_less": "销毁更少",
		"congratulations_you_burned": "恭喜您。您成功销毁了 {tokens} {midSentence} 代币 (ID: {id})。",
		"well_done_you_just_earned": "做得好。您刚刚赚取了 {t} {midSentence} 代币 (ID: {id})。",
		"total_equals": "总共 = {tokens}",
		"going_to_token_registration": "前往代币注册中...",
		"earn_event_tokens_warning": "赚取事件代币 - 警告",
		"sorry_you_already_earned": "抱歉。您已经赚取了 {eventName} 的代币。",
		"event": "事件",
		"something_went_wrong_15_second": "出现问题导致15秒延迟。",
		"connectiontype": "{connectionType}",
		"connectionType": {
			"Reward": "奖励",
			"Campaign": "活动"
		}
	},
	"theme": {
		"preview": "预览",
		"confirm": "确认",
		"confirm_to_proceed": "确认继续",
		"apply": "使用",
		"revert": "恢复",
		"default": "预设值",
		"apply_settings": "应用设置",
		"save_and_apply_theme_settings": "保存并应用主题设置?",
		"restore_default": "恢复预设值",
		"restore_to_default_tqr_theme": "恢复预设 TQR 主题?"
	}
}