
// source: https://app.redislabs.com/#/bdb/tabs/conf/10303198
const options = {
  west: {
    redisHost: 'redis-11659.c60.us-west-1-2.ec2.cloud.redislabs.com',
    redisPassword: 'anzq4lNE6FT2VfW7YiAcdFDgzasg2heY',
    redisPort: 11659,
    SECRETHANDSHAKE: 'Durian(MSW).Beer(TIGER)',
    experimenting: true,
  },
  ap: {
    redisHost: 'redis-16724.c292.ap-southeast-1-1.ec2.cloud.redislabs.com',
    redisPassword: 'ugmb9pZcdoy9tfPGdN3rIiOtianLpv3A',
    redisPort: 16724,
    SECRETHANDSHAKE: 'Durian(MSW).Beer(TIGER)',
    experimenting: true,
  },
};

module.exports = options;
