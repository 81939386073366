window.onerror = function (message, source, lineno, colno, error) {
  // `message` is the error message
  // `source` is the source URL of the script that caused the error
  // `lineno` is the line number where the error occurred
  // `colno` is the column number where the error occurred
  // `error` is an Error object containing more details about the error

  console.error('Global error handler:', error);
  console.error('Message:', message);
  console.error('Source:', source);
  console.error('Line number:', lineno);
  console.error('Column number:', colno);
};
// BASE imports
import Vue from 'vue';
import router from './router';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import vuetify from './plugins/vuetify';

import './registerServiceWorker';

import App from './App.vue';

// Socket.io
import io from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
const lctJsonOptions = require('../srv/config/redisJson.options.js');

// Brand icons
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);

Vue.config.errorHandler = function (err, vm, info) {
  // `err` is the error object
  // `vm` is the Vue instance where the error occurred
  // `info` is a string containing additional information about the error

  // You can log the error, send it to a logging service, or perform any other custom handling
  console.error('Vue error handler:', err);
  console.error('Component where error occurred:', vm);
  console.error('Additional info:', info);
};

// Vue.component('font-awesome-icon', FontAwesomeIcon);

//i18n
import i18n from './plugins/i18n';

// TODO FIX: need a more flexible way to control primary key (pk)
const pk = localStorage.getItem('pk');
console.warn('pk', pk);
Vue.prototype.$pk = pk ?? '';
Vue.prototype.$reload = false;

const pin = localStorage.getItem('pin');
console.warn('pin', pin);
Vue.prototype.$pin = pin ?? '';

import packageJson from '../package.json';
const { version, experimenting, countryOverride, da } = packageJson;

function getCountry() {
  console.warn('includes "-" ?', hostName.includes('-'));
  return hostName.includes('-') ? 'yy' : hostName.slice(0, 2);
}

// TODO pri1/sev2: should we restore this function so we don't end up with a Redis key or inageKit key like 'te.'.
// function getCountry() {
//   if (pathname.includes('tensor')) {
//     return '';
//   }
//   console.warn('hostname includes "-" ?', hostName.includes('-'));
//   return hostName.includes('-') ? 'yy:' : `${hostName.slice(0, 2)}:`;
// }

Vue.prototype.$version = version;

Vue.prototype.$experimenting = experimenting;

const hostName = window.location.hostname;
const pathname = window.location.pathname;
console.warn('hostName:', hostName);
console.warn('hostName:', pathname);
console.warn('countryOverride:', countryOverride);

const country = countryOverride ? countryOverride : getCountry();
// const country = countryOverride ? `${countryOverride}:` : `${getCountry()}`;
console.warn('country:', country);

Vue.prototype.$country = country;
Vue.prototype.$blankComponent = 3;
Vue.prototype.$delim = '#';
Vue.prototype.$capturedError = '';

// const testing= country==='19'?'19:':''
/* The `subscriptions` variable is an array that contains the names of the channels that the
client wants to subscribe to. These channels are used for receiving real-time updates from the
server using Socket.io. The client will listen for events on these channels and perform
actions based on the received data. */
//const  subscriptions = [
//   `${testing}tensor:links`,
//   `${testing}tensor:links`,
//   `${testing}tensor:vcards:todos`,
//   `${testing}tensor:links:messages:private`,
// ];
// const channels=channelArray.map((v) => `${testing}${v}`);

// console.warn('!!!!!!!!!!!!!!!!!!!!!!!!!!!');
// console.warn(subscriptions);
// const auth= { userID: pk, pin, subscriptions }
const auth = { userID: pk, pin };
console.warn(auth, JSON.stringify(auth, null, 2));
// console.warn('!!!!!!!!!!!!!!!!!!!!!!!!!!!');
// Vue.prototype.$subscriptions = subscriptions;
// Vue.prototype.$channels = channels;

const socket = io(process.env.VUE_APP_HOST, {
  auth,
});

// const debug=  da ?? (country === 'xx' || country === 'lo');
const debug = da ?? country === '19';
console.warn('debug: ', debug);
Vue.prototype.$debug = debug;

console.warn('prod :>> ', process.env.NODE_ENV != 'production');
Vue.prototype.$testing = process.env.experimenting;
Vue.prototype.$secret =
  process.env.SECRETHANDSHAKE ?? lctJsonOptions.SECRETHANDSHAKE;

Vue.use(VueSocketIOExt, socket);

Vue.prototype.$preambleBase = `tqr:${country}`;
// tensors has no country boundary like rewards, right?
// Vue.prototype.$preambleBaseTensor = `tensor:${country}`;

Vue.prototype.$myError = function (name, message, info) {
  const error = Object.create(Error.prototype);
  error.message = message;
  error.info = info;
  error.name = name;
  return error;
};

Vue.config.productionTip = false;
Vue.config.devtools = true;
const pk2 = localStorage.getItem('pk');
console.warn('pk2', pk2);
console.log('==================\n');
new Vue({
  vuetify,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
