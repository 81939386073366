import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/vis',
  //   name: 'vis',
  //   component: () => import('./views/scratch/vue-vis-network-test.vue'),
  //   props: true,
  // },

  {
    path: '/scratch',
    name: 'Scratch',
    component: () => import('../srv/tests/scratch.vue'),
    props: true,
  },

  {
    path: '/theme',
    name: 'Theme',
    component: () => import('./views/Theme.vue'),
  },

  {
    path: '/tensor/vCard/org/:vid/:card',
    name: 'Tensor.Org.Vcard.Vid.Label',
    component: () => import('./views/tensor/Vcard.vue'),
    meta: { ctx: 'vcard', isOrg: true },
    props: true,
    beforeEnter: (to, from, next) => {
      localStorage.setItem('previousRoute', from.name);
      // TODO Why is name null? and why do we care?
      console.warn('Tensor.Org.Vcard.Vid.Label: from.name', from.name);
      next();
    },
  },
  {
    path: '/tensor/vCard/:vid/:card',
    name: 'Tensor.Vcard.Vid.Label',
    component: () => import('./views/tensor/Vcard.vue'),
    meta: { ctx: 'vcard', isOrg: false },
    props: true,
    beforeEnter: (to, from, next) => {
      localStorage.setItem('previousRoute', from.name);
      console.warn('Tensor.Vcard.Vid.Label: from.name', from.name);

      next();
    },
  },

  {
    path: '/tensor/vCard/:vid',
    name: 'Tensor.Org.Vcard.Vid',
    component: () => import('./views/tensor/Vcard.vue'),
    meta: { ctx: 'vcard', isOrg: true },
    props: true,
    beforeEnter: (to, from, next) => {
      localStorage.setItem('previousRoute', from.name);
      next();
    },
  },
  {
    path: '/tensor/vCard/:vid',
    name: 'Tensor.Vcard.Vid',
    component: () => import('./views/tensor/Vcard.vue'),
    meta: { ctx: 'vcard', isOrg: false },
    props: true,
    beforeEnter: (to, from, next) => {
      localStorage.setItem('previousRoute', from.name);
      next();
    },
  },

  {
    path: '/tensor/links/:vid',
    name: 'Tensor.Links.Vid',
    component: () => import('./views/tensor/Links.vue'),
    meta: { ctx: 'links' },
    props: true,
    beforeEnter: (to, from, next) => {
      next();
    },
  },

  {
    path: '/tensor/topic/template/:id',
    name: 'Tensor.Topics.Templates.ID',
    component: () => import('./views/tensor/TopicEditor.vue'),
    meta: { ctx: 'Topics:Templates' },
    props: true,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: '/tensor/topic/template',
    name: 'Tensor.Topic.Template',
    component: () => import('./views/tensor/TopicEditor.vue'),
    meta: { ctx: 'Topics:Templates' },
    props: true,
    beforeEnter: (to, from, next) => {
      next();
    },
  },

  {
    path: '/tensor/topic/:id',
    name: 'Tensor.Topic',
    component: () => import('./views/tensor/TopicEditor.vue'),
    meta: { ctx: 'Topics' },
    props: true,
    beforeEnter: (to, from, next) => {
      next();
    },
  },

  {
    path: '/tensor/topics',
    name: 'Tensor.Topics.All',
    component: () => import('./views/tensor/Topics.vue'),
    meta: { ctx: 'Topics' },
    props: true,
    beforeEnter: (to, from, next) => {
      next();
    },
  },

  {
    path: '/tensor/listings/:vid',
    name: 'Tensor.Listings.Vid',
    component: () => import('./views/tensor/Listings.vue'),
    meta: { ctx: 'listings' },
    props: true,
    beforeEnter: (to, from, next) => {
      localStorage.setItem('previousRoute', from.name);
      next();
    },
  },
  {
    path: '/tensor/listings',
    name: 'Tensor.Listings',
    component: () => import('./views/tensor/Listings.vue'),
    meta: { ctx: 'listings' },
    props: true,
    beforeEnter: (to, from, next) => {
      localStorage.setItem('previousRoute', from.name);
      next();
    },
  },

  {
    path: '/tutorials',
    name: 'Tutorials',
    component: () => import('./views/tensor/Tutorials.vue'),

    props: true,
  },
  {
    path: '/practices',
    name: 'Practices',
    component: () => import('./views/tensor/Practices.vue'),

    props: true,
  },

  {
    path: '/qrPrintCard/:owner/:nonce',
    name: 'qrPrintCard',
    component: () => import('@/components/qrPrintCard.vue'),
    props: true,
  },

  {
    path: '/lab/:channelId',
    name: 'Lab Document',
    component: () => import('./views/lab/Lab.vue'),
    props: true,
  },
  {
    path: '/tiny/:type',
    name: 'Tiny Document',
    component: () => import('./views/lab/Lab.vue'),
    props: true,
  },

  {
    path: '/lab',
    name: 'Lab',
    component: () => import('./views/lab/Lab.vue'),
    props: true,
  },

  {
    path: '/new',
    name: 'Home.newPK',
    component: () => import('@/views/Home2.vue'),
    props: true,
  },
  // /:pk may include a query.pin value
  {
    path: '/:pk',
    name: 'Home.setPK',
    component: () => import('@/views/Home2.vue'),
    props: true,
  },

  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home2.vue'),
    props: true,
  },

  // TODO pri2/sev2: has this ever worked? have we gotten down the router array this far without being stopped at /:pk
  {
    path: '/:indexedComponent',
    name: 'Home.Indexed',
    component: () => import('@/views/Home2.vue'),
    props: true,
  },

  {
    path: '/:catchAll(.*)',
    name: 'ErrorPage',
    component: () => import('@/views/ErrorPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const hostname = location.hostname;
  const pathname = location.pathname;

  if (pathname === '' || pathname === '/' || to.path === '/') {
    switch (hostname) {
      case 'sg.tqrtoken.net':
      case 'uk.tqrtoken.net':
      case 'us.tqrtoken.net':
      case 'zz.tqrtoken.net':
        window.location.href = 'https://tqrtoken.com';
        next(false);
        break;
      default:
        next();
    }
  } else {
    next();
  }
});

export default router;
