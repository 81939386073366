<template>
  <div>
    <v-row no-gutters align="center">
      <v-col>
        <section class="svg">
          <svg
            version="1.1"
            id="tqrTokenIcon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 192.000000 192.000000"
            xml:space="preserve"
            @click="toggleSvg"
            :width="width"
            :height="height"
          >
            <g
              transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)"
              :fill="fill"
              stroke="none"
            >
              <path
                d="M964 1851 c-50 -22 -71 -102 -39 -148 20 -29 75 -46 107 -34 64 24
88 107 46 156 -25 28 -79 40 -114 26z m71 -61 c37 -41 -24 -97 -63 -58 -17 17
-15 44 4 62 21 21 37 20 59 -4z"
              />
              <path
                d="M798 1843 c-31 -5 -39 -36 -13 -51 10 -5 18 -31 23 -73 7 -61 8 -64
32 -61 14 2 26 5 27 5 0 1 -4 44 -9 95 -7 57 -15 91 -22 91 -6 -1 -23 -4 -38
-6z"
              />
              <path
                d="M1165 1819 c-4 -10 -4 -27 -1 -37 4 -10 -3 -44 -14 -76 l-19 -57 22
-8 c12 -5 25 -6 28 -2 9 8 62 172 58 175 -2 2 -18 8 -36 14 -28 9 -33 8 -38
-9z"
              />
              <path
                d="M615 1791 c-85 -35 -74 -166 16 -186 37 -8 93 18 108 51 38 84 -40
169 -124 135z m65 -61 c24 -24 24 -30 7 -54 -9 -12 -23 -17 -42 -14 -25 2 -31
8 -33 33 -3 19 2 33 14 42 24 17 30 17 54 -7z"
              />
              <path
                d="M1279 1731 c-21 -22 -29 -39 -29 -66 0 -105 155 -132 189 -32 35 97
-86 171 -160 98z m99 -33 c27 -27 7 -78 -29 -78 -21 0 -49 26 -49 45 0 35 53
58 78 33z"
              />
              <path
                d="M435 1686 c-25 -20 -28 -26 -16 -40 7 -9 19 -16 26 -16 7 0 29 -25
49 -56 33 -49 39 -54 54 -43 9 8 19 14 21 16 4 2 -88 151 -98 157 -4 3 -20 -6
-36 -18z"
              />
              <path
                d="M1472 1588 c-28 -31 -37 -72 -22 -109 33 -79 153 -75 180 6 23 69
-17 125 -90 125 -35 0 -52 -5 -68 -22z m106 -65 c2 -24 -2 -34 -17 -43 -28
-15 -61 3 -61 33 0 31 20 48 50 45 20 -2 26 -9 28 -35z"
              />
              <path
                d="M298 1561 c-68 -60 -29 -162 62 -165 100 -2 137 134 49 180 -42 21
-75 17 -111 -15z m86 -34 c12 -9 17 -23 14 -42 -2 -25 -8 -31 -32 -33 -16 -2
-32 2 -37 10 -14 22 -10 56 9 67 22 13 25 13 46 -2z"
              />
              <path
                d="M870 1574 c-144 -26 -245 -78 -345 -179 -130 -130 -180 -256 -179
-445 1 -122 30 -223 89 -316 36 -57 112 -144 125 -144 6 0 131 146 203 237 17
22 17 23 -17 74 -66 95 -72 195 -18 292 70 127 232 172 362 101 90 -49 134
-126 134 -234 0 -80 -20 -135 -63 -174 -12 -11 -21 -24 -21 -30 0 -6 35 -52
78 -101 42 -50 92 -109 110 -130 l32 -40 56 60 c148 160 200 401 130 607 -37
109 -72 165 -155 248 -78 76 -157 124 -259 156 -48 15 -216 26 -262 18z"
              />
              <path
                d="M1660 1282 c-52 -52 -39 -131 27 -158 67 -29 133 16 133 90 0 49 -34
86 -87 93 -37 5 -45 2 -73 -25z m94 -38 c30 -30 13 -74 -29 -74 -42 0 -59 44
-29 74 8 9 22 16 29 16 7 0 21 -7 29 -16z"
              />
              <path
                d="M123 1246 c-23 -20 -28 -32 -28 -71 0 -39 5 -51 28 -71 30 -26 83
-32 119 -13 11 6 26 26 34 46 40 96 -74 177 -153 109z m95 -38 c17 -17 15 -53
-3 -68 -8 -7 -25 -10 -40 -6 -24 6 -30 21 -25 56 4 29 46 40 68 18z"
              />
              <path
                d="M1804 1070 c-5 -5 -38 -13 -72 -16 -57 -6 -62 -9 -62 -30 0 -22 4
-24 58 -24 31 0 74 3 95 7 35 5 38 8 35 37 -3 33 -33 47 -54 26z"
              />
              <path
                d="M70 1020 c0 -8 -3 -25 -6 -36 -4 -17 0 -22 20 -26 14 -3 27 0 29 7 4
8 25 10 67 7 46 -3 65 -1 71 9 19 30 7 36 -86 45 -82 8 -95 7 -95 -6z"
              />
              <path
                d="M1695 926 c-32 -32 -40 -69 -25 -107 16 -39 46 -59 88 -59 47 0 71
16 88 57 29 68 -16 133 -91 133 -25 0 -44 -8 -60 -24z m99 -48 c13 -19 14 -25
2 -43 -26 -39 -76 -26 -76 19 0 46 48 62 74 24z"
              />
              <path
                d="M125 898 c-60 -34 -64 -137 -7 -167 36 -19 96 -13 120 12 76 82 -16
210 -113 155z m75 -58 c24 -24 25 -34 4 -54 -30 -31 -74 -13 -74 31 0 20 22
42 43 43 4 0 16 -9 27 -20z"
              />
              <path
                d="M1627 708 c-4 -12 -5 -23 -4 -24 11 -7 158 -64 165 -64 6 0 15 14 21
32 13 37 0 52 -36 43 -12 -3 -45 4 -73 15 -63 25 -66 25 -73 -2z"
              />
              <path
                d="M225 681 c-50 -15 -75 -48 -75 -101 0 -46 47 -90 95 -90 107 0 133
161 30 188 -16 5 -39 6 -50 3z m55 -66 c17 -20 5 -62 -21 -70 -24 -8 -59 19
-59 45 0 37 55 55 80 25z"
              />
              <path
                d="M1569 581 c-38 -39 -39 -87 -3 -130 21 -26 33 -31 67 -31 45 1 67 13
85 47 53 99 -70 193 -149 114z m99 -33 c28 -28 7 -78 -33 -78 -27 0 -45 18
-45 45 0 27 18 45 45 45 12 0 26 -5 33 -12z"
              />
              <path
                d="M332 464 c-40 -30 -72 -61 -72 -68 0 -7 9 -21 19 -30 15 -14 24 -15
40 -7 11 6 18 17 15 24 -3 8 15 27 40 45 56 41 60 47 44 71 -13 19 -17 17 -86
-35z"
              />
              <path
                d="M1409 401 c-43 -43 -37 -102 15 -143 68 -56 174 22 146 107 -23 71
-107 89 -161 36z m99 -33 c16 -16 15 -53 -3 -68 -33 -27 -81 15 -64 58 9 24
47 30 67 10z"
              />
              <path
                d="M409 381 c-22 -23 -29 -39 -29 -69 0 -89 102 -127 163 -61 38 41 37
90 -2 130 -22 21 -39 29 -66 29 -27 0 -44 -8 -66 -29z m95 -37 c19 -18 20 -28
6 -55 -19 -35 -80 -18 -80 22 0 21 26 49 45 49 7 0 21 -7 29 -16z"
              />
              <path
                d="M604 230 l-42 -79 24 -16 c13 -8 28 -15 33 -15 15 0 33 38 22 44 -5
4 4 33 21 66 30 61 30 80 0 80 -11 0 -32 -28 -58 -80z"
              />
              <path
                d="M1209 281 c-53 -54 -34 -140 36 -159 69 -19 125 25 125 98 0 46 -47
90 -95 90 -27 0 -44 -8 -66 -29z m93 -38 c32 -29 16 -73 -27 -73 -42 0 -59 44
-29 74 20 20 33 20 56 -1z"
              />
              <path
                d="M741 239 c-48 -45 -47 -120 2 -154 62 -43 157 5 157 80 0 79 -102
126 -159 74z m93 -48 c23 -25 13 -59 -20 -67 -30 -8 -54 11 -54 42 0 22 22 43
46 44 6 0 19 -9 28 -19z"
              />
              <path
                d="M1072 252 c-19 -13 6 -195 26 -187 7 3 25 5 38 5 22 0 24 3 18 23 -4
12 -11 25 -16 28 -7 4 -28 102 -28 132 0 9 -22 9 -38 -1z"
              />
            </g>
          </svg>
        </section>
      </v-col>
      <v-col v-if="showText">
        <v-row no-gutters align="center">
          <v-col class="text-left text-h5">TQR</v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLiked: false,
    };
  },
  props: {
    fill: {
      type: String,
      default: 'white',
    },
    height: {
      type: String,
      default: '36',
    },
    width: {
      type: String,
      default: '36',
    },
    showText: { type: Boolean, default: true },
  },
  computed: {
    fillMe() {
      return this.isLiked ? '#E0E0E0 ' : 'black';
    },
  },
  methods: {
    toggleSvg() {
      this.isLiked = !this.isLiked;
    },
  },
};
</script>

<style>
#tqrTokenIcon {
  fill: currentColor;
}
</style>
