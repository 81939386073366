import $vuetify from 'vuetify/src/locale/th.ts'

export default {
  $vuetify,
  "app": {
		"manage": "จัดการ",
		"new_user_setup": "ตั้งค่าผู้ใช้ใหม่",
		"access_manager": "ผู้จัดการเข้าถึง",
		"primary_key_manager": "ผู้จัดการคีย์หลัก",
		"your_pk_must_be_blank": "PK ของคุณต้องเป็นช่องว่าง (เพื่อให้เซิร์ฟเวอร์ให้คุณอันใหม่) หรือลงท้ายด้วยเครื่องหมายหรือด้วยเลข (g.g., \"-0\")",
		"to_manage_your_pk": "ในการจัดการ PK ของคุณให้ให้ PIN",
		"anonymous_activation": "การเปิดใช้งานแบบไม่ระบุชื่อ",
		"primary_key_management": "การจัดการคีย์หลัก",
		"primary_key_tqrtoken_services": "นี่คือคีย์หลักใหม่ของคุณในการเข้าถึงบริการ TQR Token ทั้งหมด:",
		"primary_key_data_on_device": "นี่คือคีย์หลักของคุณสำหรับข้อมูลทั้งหมดในอุปกรณ์นี้:",
		"to_secure_your_primary_key": "ในกรณีที่เกิดเหตุฉุกเฉินให้คัดลอกคีย์ไปยังส่วน {b_notes} บนบัตรสำหรับติดต่อของคุณในแอปติดต่อบนอุปกรณ์ของคุณ.",
		"notes": "บันทึก",
		"to_share_data_between_devices": "ในการแบ่งปันข้อมูลระหว่างอุปกรณ์ให้เขียนทับคีย์หลักของคุณด้วยคีย์หลักจากอุปกรณ์อื่นที่นี่:",
		"to_enable_multiple_devices": "ในการเปิดใช้งานอุปกรณ์หลายเครื่องให้วางคีย์หลักอื่น",
		"we_suggest_a_pin": "เราแนะนำ PIN ที่คุณใช้ที่อื่น",
		"to_secure_your_pk": "ในการป้องกัน PK ของคุณ (ตามต้องการ) ให้ป้อน PIN ที่มี 4-6 หลัก",
		"pin_changed_to": "เปลี่ยนรหัส PIN เป็น:",
		"pin_removed": "ลบ PIN แล้ว",
		"security_pin": "ความปลอดภัย PIN",
		"use_only_digits": "ใช้ตัวเลขเท่านั้น (4-6 หลัก)"
  },
  "newuser": {
    "anonymous_activation": "การเปิดใช้งานแบบไม่ระบุชื่อ",
    "anywhere_anyone_anytime": "ทุกที่ ทุกคน ทุกเวลา",
    "no_app": "ไม่มีแอป",
		"no_tracking": "ไม่มีการติดตาม",
    "your_tensor_profile": "โปรไฟล์ Tensor ของคุณ"
  },
  "confirmdialog": {
    "cancel": "ยกเลิก",
		"ok": "ตกลง"
  },
  "prompt": {
		"done": "เสร็จสิ้น",
    "cancel": "ยกเลิก"
  },
  "aegis2": {
		"positive_number_only": "จำนวนบวกเท่านั้น.",
		"required": "ต้องระบุ.",
		"add": "เพิ่ม",
		"edit": "แก้ไข",
		"a_connection": "การเชื่อมต่อ",
		"new_campaign": "แคมเปญใหม่",
		"new_reward": "รางวัลใหม่",
		"root_connection": "การเชื่อมต่อรูท",
		"your_base_of_operations": "ฐานของการดำเนินการของคุณ",
		"typically_this_is_your_brand": "โดยทั่วไป นี้คือชื่อแบรนด์ของคุณ",
		"branch_connection": "การเชื่อมต่อสาขา",
		"delimit_organization_parts_with": "ใช้เครื่องหมาย @ ในการแยกส่วนขององค์กร",
		"minimum_tokens": "ขั้นต่ำของโทเคน",
		"connections": "การเชื่อมต่อ",
		"add_connection": "เพิ่มการเชื่อมต่อ",
		"register_account": "ลงทะเบียนบัญชี",
		"reward": "รางวัล",
		"campaign": "แคมเปญ",
		"venue": "สถานที่จัดงาน",
		"type": "ชนิด",
		"use_only_at_to_separate": "ใช้เฉพาะ @ เพื่อแยกค่าชื่อ",
		"correct_your_data": "แก้ไขข้อมูลของคุณก่อนที่จะบันทึก.",
		"register": "ลงทะเบียน"
  },
  "rewards": {
    "earn_tokens": "รับโทเคน",
		"consumer_scans_qr_to_earn": "ผู้บริโภคสแกนรหัสคิวอาร์นี้เพื่อ {b_earn} โทเคน.",
		"earn": "รับ",
		"qr_earn_url": "URL รับโทเคนคิวอาร์",
		"goes_to_the_same_place": "ไปที่ตำแหน่งเดียวกันกับรหัสคิวอาร์",
		"copy_earn_url": "คัดลอก URL รับ",
		"rewards_view": "มุมมองของรางวัล",
		"consumer_scans_qr_to_burn": "ผู้บริโภคสแกนรหัสคิวอาร์นี้เพื่อ {b_burn} โทเคน.",
		"burn": "เผา",
		"qr_burn_url": "URL เผาคิวอาร์",
		"copy_burn_url": "คัดลอก URL เผา",
		"earn_url": "URL รับโทเคน",
		"redirect_consumer_after_earning_tokens": "เปลี่ยนเส้นทางผู้บริโภคหลังจากได้รับโทเคน",
		"burn_url": "URL เผา",
		"redirect_consumer_after_burning_tokens": "เปลี่ยนเส้นทางผู้บริโภคหลังจากการเผาโทเคน",
		"invalid_url_keep_adding": "URL ไม่ถูกต้อง ให้เพิ่ม (หรือตรวจสอบกับ) ข้อมูลที่ต้องการ.",
		"edit_reward_html": "แก้ไข HTML รางวัล",
		"reward_view_html": "มุมมอง HTML ของรางวัล",
		"enter_the_reward_menu_content": "ใส่เนื้อหาเมนูรางวัลในรูปแบบ HTML.",
		"cancel": "ยกเลิก",
		"save": "บันทึก",
		"copied": "คัดลอก",
		"thanks": "ขอบคุณ"
  },
  "campaigns": {
		"campaign_events": "กิจกรรมของแคมเปญ",
		"add_campaign": "เพิ่มแคมเปญ",
		"add_a_new_campaign": "เพิ่มแคมเปญใหม่หรือเพิ่มข้อมูลที่แก้ไขในแคมเปญที่เลือก",	
		"welcome_to_tqr": "ยินดีต้อนรับสู่ TQR",
		"to_get_started": "เพื่อเริ่มต้น:",
		"click_add_campaign_button_above": "คลิกที่ปุ่ม {b_add_campaign} ด้านบน คุณจะเห็นแคมเปญของคุณปรากฏขึ้น",
		"click_the_campaign_label": "คลิกที่ป้ายประกาศ {b_campaign} หรือปุ่ม {b_add_event} ข้างข้างแคมเปญใหม่ของคุณ.",
		"campaign": "แคมเปญ",
		"add_event": "เพิ่มกิจกรรม",
		"fill_in_the_dialog": "กรอกในกล่องโต้ตอบและกดปุ่ม {b_ok}.",
		"ok": "ตกลง",
		"to_see_your_events": "ในการดูเหตุการณ์ของคุณ คลิกที่ป้ายประกาศแคมเปญ หากคุณต้องการแก้ไขเหตุการณ์ใด ๆ คลิกที่ปุ่ม {b_edit_event} บนป้ายประกาศเหตุการณ์.",
		"edit_event": "แก้ไขเหตุการณ์",
		"click_the_add_campaign_button": "คลิกที่ปุ่ม {b_add_campaign} เพื่อล้างข้อความต้อนรับนี้.",
		"add_a_campaign_connection": "เพิ่มการเชื่อมต่อแคมเปญ",
		"root_connection": "การเชื่อมต่อรูท",
		"your_base_of_operations": "ฐานของการดำเนินการของคุณ",
		"typically_this_is_your_brand": "โดยทั่วไป นี้คือชื่อแบรนด์ของคุณ",
		"branch_connection": "การเชื่อมต่อสาขา",
		"delimit_organization_parts_with": "ใช้เครื่องหมาย @ ในการแยกส่วนขององค์กร",
		"required": "ต้องระบุ",
		"archive": "เก็บถาวร",
		"campaign_to_archive": "แคมเปญที่จะเก็บถาวร",
		"if_you_archive_this_campaign": "หากคุณเก็บถาวรแคมเปญนี้ คุณจะไม่เห็นมันปรากฏขึ้นในการเชื่อมต่อของคุณ สิ่งที่แนบไว้กับแคมเปญที่เก็บถาวร จะไม่สามารถใช้งานได้อีกต่อไป.",
		"new_event_name": "ชื่อเหตุการณ์ใหม่:",
		"use_only_at_to_separate": "ใช้เฉพาะ @ เพื่อแยกค่าชื่อ",
		"campaign_url": "URL ของแคมเปญ",
		"event_tokens_qr": "โทเค็นกิจกรรม QR",
		"copy_qr_url_to": "คัดลอก QR URL เพื่อรับหรือเบิร์นโทเค็น",
		"copied": "คัดลอก",
		"thanks": "ขอบคุณ",
		"qrtype": "{qrType}",
		"qrType": {
			"Burn": "เผา",
			"Earn": "รับ",
			"Campaign": "แคมเปญ"
		}
  },
  "eventcard": {
    "event_details": "รายละเอียดเหตุการณ์",
		"earn_qr": "QR รับ",
		"click_to_print_event_earn": "คลิกเพื่อพิมพ์รหัสคิวอาร์นี้เพื่อรับโทเคน",
		"burn_qr": "QR เผา",
		"click_to_print_event_burn": "คลิกเพื่อพิมพ์รหัสคิวอาร์นี้เพื่อเผาโทเคน",
		"event_name": "ชื่อเหตุการณ์",
		"campaigns_event_name": "ชื่อเหตุการณ์ของแคมเปญ",
		"event_tokens_earned": "โทเคนที่ได้รับ",
		"number_of_tokens_earned": "จำนวนโทเคนที่ได้รับด้วยเหตุการณ์",
		"earn_redirect_url": "URL เปลี่ยนเส้นทางหลังจากการรับโทเคน",
		"url_after_earning_tokens": "URL หลังจากได้รับโทเคน",
		"burn_redirect_url": "URL เผาโทเคน",
		"url_to_burn_tokens": "URL สำหรับเปลี่ยนเส้นทางไปยังโทเคนเผา",
		"event_banner_url": "URL ของแบนเนอร์เหตุการณ์",
		"image_to_display_while_earning": "รูปภาพที่แสดงขณะรับโทเคน",
		"event_burn_banner_url": "URL ตัวอย่างของเหตุการณ์ที่เผา",
		"image_to_display_while_burning": "รูปภาพที่แสดงขณะเผาโทเคน",
		"last_update": "อัปเดตล่าสุด:",
		"save": "บันทึก",
		"archive": "เก็บถาวร",
		"event_to_archive": "เหตุการณ์ที่จะเก็บถาวร",
		"if_you_archive_this_event": "หากคุณเก็บถาวรเหตุการณ์นี้ คุณจะไม่เห็นมันปรากฏขึ้นในรายการเหตุการณ์ของคุณ.",
		"archive_event": "เก็บถาวรเหตุการณ์",
		"adding_event_data": "กำลังเพิ่มข้อมูลเหตุการณ์...",
		"defer_earn": "เลื่อนการรับ",
		"defer_earn_token_after_redirect": "เลื่อนรับโทเค็นหลังจากเปลี่ยนเส้นทาง"
  },
  "campaignevent": {
    "copy_qr_url_earn_burn": "คัดลอก URL คิวอาร์เพื่อรับหรือเผาโทเคน",
		"event_tokens_qr": "คิวอาร์โทเคนเหตุการณ์",
		"qrtype": "{qrType}",
		"copied": "คัดลอก",
		"thanks": "ขอบคุณ",
		"qrType": {
			"Burn": "เผา",
			"Earn": "รับ",
			"Redirect": "เปลี่ยนเส้นทาง"
		}
  },
	"view": {
		"tokens_to_burn_for": "โทเค็นที่จะเผา",
		"counting_earnings": "นับรายได้...",
		"last_earned": "ได้รับล่าสุด：",
		"burn_all": "เผาทั้งหมด",
		"burn_some": "เผาบางส่วน",
		"register": "ลงทะเบียน",
		"burn_tokens": "เผาโทเค็น",
		"earn_tokens": "รับโทเค็น",
		"show_this_to_the_provider": "แสดงสิ่งนี้ต่อผู้ให้บริการเพื่อรับเครดิต (หรือยกเลิกหากจำเป็น).",
		"you_are_about_to_burn": "คุณกำลังจะเผาโทเค็น <strong>{thisTokens}</strong> ทั้งหมด.",
		"you_have_no_tokens": "คุณไม่มีโทเค็นที่จะเผา",
		"you_have_available_tokens": "คุณมีโทเค็น <strong>{availableTokens}</strong> ที่จะเผา.",
		"we_are_sorry": "ขอโทษด้วย คุณยังไม่มีโทเค็นที่จะเบิร์น.",
		"token_burn": "โทเค็นเบิร์น",
		"tokens_to_burn": "โทเค็นเบิร์น",
		"burn_more": "มากกว่า",
		"burn_less": "น้อย",
		"congratulations_you_burned": "ยินดีด้วย. คุณเผา {tokens} {midSentence} token (ID: {id}).",
		"well_done_you_just_earned": "ทำได้ดี. คุณเพิ่งได้รับโทเค็น {t} {midSentence} token (ID: {id}).",
		"total_equals": "รวม = {tokens}",
		"going_to_token_registration": "ไปที่การลงทะเบียนโทเค็น...",
		"earn_event_tokens_warning": "รับโทเค็นของอีเว้นท์ - คำเตือน",
		"sorry_you_already_earned": "ขอโทษ. คุณได้รับโทเค็น {eventName} แล้ว.",
		"event": "กิจกรรม",
		"something_went_wrong_15_second": "เกิดข้อผิดพลาดซึ่งทำให้เกิดความล่าช้า15วินาที.",
		"connectiontype": "{connectionType}",
		"connectionType": {
			"Reward": "รางวัล",
			"Campaign": "แคมเปญ"
		}
	},
	"theme": {
		"preview": "ดูตัวอย่าง",
		"confirm": "ยืนยัน",
		"confirm_to_proceed": "ยืนยันเพื่อดำเนินการต่อ",
		"apply": "ใช้",
		"revert": "ย้อนกลับ",
		"default": "ค่าเริ่มต้น",
		"apply_settings": "ใช้การตั้งค่า",
		"save_and_apply_theme_settings": "บันทึกและใช้การตั้งค่าธีม?",
		"restore_default": "คืนค่าเริ่มต้น",
		"restore_to_default_tqr_theme": "คืนค่าเป็นธีม TQR เริ่มต้น?"
	}
}