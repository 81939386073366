import $vuetify from 'vuetify/src/locale/zh-Hant.ts'

export default {
  $vuetify,
  "app": {
		"manage": "管理",
		"new_user_setup": "新用戶設置",
		"access_manager": "使用權管理窗",
		"primary_key_manager": "PK 管理窗",
		"your_pk_must_be_blank": "您的 PK 必須為空白（以便服務器會給您一個新的 PK），或者以 “-” 和一個數字為結尾 (g.g., \"-0\")",
		"to_manage_your_pk": "要管理您的 PK，請提供其 PIN 碼",
		"anonymous_activation": "匿名激活",
		"primary_key_management": "PK 管理",
		"primary_key_tqrtoken_services": "這是您訪問所有 TQR Token 服務的新 PK:",
		"primary_key_data_on_device": "這是您在此設備上所有數據的 PK:",
		"to_secure_your_primary_key": "為了在發生意外情況時保護您的 PK，請將該 PK 複製到您在設備上的聯絡人應用程序中您自己的聯絡卡的{b_notes} 部分。",
		"notes": "筆記",
		"to_share_data_between_devices": "要在您的設備之間共享數據，請使用另一個設備的 PK 覆蓋這個 PK:",
		"to_enable_multiple_devices": "要啟用多個設備，請粘貼另一個 PK",
		"we_suggest_a_pin": "我們建議您使用在其他地方使用的 PIN 碼",
		"to_secure_your_pk": "（可選）要保護您的 PK，請輸入4-6位數的 PIN 碼",
		"pin_changed_to": "PIN 已換成:",
		"pin_removed": "PIN 已移除",
		"security_pin": "安全碼 PIN",
		"use_only_digits": "僅使用數字（4-6個）"
  },
  "newuser": {
    "anonymous_activation": "匿名激活",
    "anywhere_anyone_anytime": "任何地方，任何人，任何時候",
    "no_app": "無應用程式",
		"no_tracking": "無追蹤",
    "your_tensor_profile": "您的Tensor個人資料"
  },
  "confirmdialog": {
    "cancel": "取消",
		"ok": "OK"
  },
  "prompt": {
		"done": "完成",
    "cancel": "取消"
  },
  "aegis2": {
		"positive_number_only": "只有正數。",
		"required": "必須。",
		"add": "增加",
		"edit": "更改",
		"a_connection": "一個連接",
		"new_campaign": "新活動",
		"new_reward": "新獎勵",
		"root_connection": "根支連接",
		"your_base_of_operations": "您的運營地",
		"typically_this_is_your_brand": "通常這是您的品牌名稱",
		"branch_connection": "分支連接",
		"delimit_organization_parts_with": "使用 @ 分隔您的組織",
		"minimum_tokens": "最低數量代幣",
		"connections": "連接",
		"add_connection": "增加連接",
		"register_account": "註冊賬號",
		"reward": "獎勵",
		"campaign": "活動",
		"venue": "地點",
		"type": "類型",
		"use_only_at_to_separate": "只使用 @ 來分隔名字",
		"correct_your_data": "在保存數據之前更正數據。",
		"register": "註冊"
  },
  "rewards": {
		"earn_tokens": "賺取代幣",
		"consumer_scans_qr_to_earn": "消費者掃描此二維碼以{b_earn}代幣。",
		"earn": "賺取",
		"qr_earn_url": "二維碼的賺取網址",
		"goes_to_the_same_place": "前往與二維碼相同的位置",
		"copy_earn_url": "複製賺取網址",
		"rewards_view": "獎勵視圖",
		"consumer_scans_qr_to_burn": "消費者掃描此二維碼以{b_burn}代幣。",
		"burn": "銷毀",
		"qr_burn_url": "二維碼的銷毀網址",
		"copy_burn_url": "複製銷毀網址",
		"earn_url": "賺取網址",
		"redirect_consumer_after_earning_tokens": "在賺取代幣後將消費者重新導向",
		"burn_url": "銷毀網址",
		"redirect_consumer_after_burning_tokens": "在銷毀代幣後將消費者重新導向",
		"invalid_url_keep_adding": "無效的網址。請繼續增加（或檢查）所需的數據。",
		"edit_reward_html": "編輯獎勵 HTML",
		"reward_view_html": "獎勵視圖 HTML",
		"enter_the_reward_menu_content": "以HTML格式輸入獎勵菜單的內容。",
		"cancel": "取消",
		"save": "保存",
		"copied": "已複製",
		"thanks": "謝謝"
  },
  "campaigns": {
		"campaign_events": "活動和事件",
		"add_campaign": "增加活動",
		"add_a_new_campaign": "新增一個活動或將編輯後的數據添加到選定的活動中",	
		"welcome_to_tqr": "歡迎來到 TQR",
		"to_get_started": "開始：",
		"click_add_campaign_button_above": "點擊上方的 {b_add_campaign} 按鈕。您將看到您的活動出現。",
		"click_the_campaign_label": "點擊 {b_campaign} 標籤或您的新活動旁邊的 {b_add_event} 按鈕。",
		"campaign": "活動",
		"add_event": "增加事件",
		"fill_in_the_dialog": "填寫對話框，然後點擊 {b_ok} 按鈕。",
		"ok": "OK",
		"to_see_your_events": "要查看您的事件，請點擊活動標籤。如果您需要編輯一個事件，請點擊該事件標籤上的 {b_edit_event}。",
		"edit_event": "編輯事件",
		"click_the_add_campaign_button": "點擊 {b_add_campaign} 按鈕以清除此歡迎消息。",
		"add_a_campaign_connection": "增加一個活動連接",
		"root_connection": "根支連接",
		"your_base_of_operations": "您的運營地",
		"typically_this_is_your_brand": "通常這是您的品牌名稱",
		"branch_connection": "分支連接",
		"delimit_organization_parts_with": "使用 @ 分隔您的組織",
		"required": "必須。",
		"archive": "存檔",
		"campaign_to_archive": "將活動存檔",
		"if_you_archive_this_campaign": "如果您將此活動存檔，它將不會出現在您的連接中。任何附加到存檔活動的事件將不再可用。",
		"new_event_name": "新事件名稱:",
		"use_only_at_to_separate": "只使用 @ 來分隔名字",
		"campaign_url": "活動網址",
		"event_tokens_qr": "事件代幣二維碼",
		"copy_qr_url_to": "複製二維碼網址以賺取或銷毀代幣",
		"copied": "已複製",
		"thanks": "謝謝",
		"qrtype": "{qrType}",
		"qrType": {
			"Burn": "銷毀",
			"Earn": "賺取",
			"Campaign": "活動"
		}
  },
  "eventcard": {
		"event_details": "事件詳情",
		"earn_qr": "賺取二維碼",
		"click_to_print_event_earn": "點擊以打印事件的賺取二維碼",
		"burn_qr": "銷毀二維碼",
		"click_to_print_event_burn": "點擊以打印事件的銷毀二維碼",
		"event_name": "事件名稱",
		"campaigns_event_name": "活動的事件名稱",
		"event_tokens_earned": "賺取的事件代幣",
		"number_of_tokens_earned": "事件賺取的代幣數量",
		"earn_redirect_url": "賺取網址",
		"url_after_earning_tokens": "賺取代幣後的網址",
		"burn_redirect_url": "銷毀網址",
		"url_to_burn_tokens": "銷毀代幣後的網址",
		"event_banner_url": "事件橫幅的網址",
		"image_to_display_while_earning": "賺取代幣時顯示的圖像",
		"event_burn_banner_url": "事件銷毀橫幅的網址",
		"image_to_display_while_burning": "銷毀代幣時顯示的圖像",
		"last_update": "最後的更新:",
		"save": "保存",
		"archive": "存檔",
		"event_to_archive": "將事件存檔",
		"if_you_archive_this_event": "如果您將此事件存檔，它將不會出現在您的事件列表中。",
		"archive_event": "存檔事件",
		"adding_event_data": "添加事件數據...",
		"defer_earn": "延遲賺取",
		"defer_earn_token_after_redirect": "網址後延遲賺取代幣"
  },
  "campaignevent": {
		"copy_qr_url_earn_burn": "複製二維碼網址以賺取或銷毀代幣",
		"event_tokens_qr": "事件代幣二維碼",
		"qrtype": "{qrType}",
		"copied": "已複製",
		"thanks": "謝謝",
		"qrType": {
			"Burn": "銷毀",
			"Earn": "賺取",
			"Redirect": "重新導向"
		}
  },
	"view": {
		"tokens_to_burn_for": "為此獎勵而要銷毀的代幣",
		"counting_earnings": "計算收益中...",
		"last_earned": "最後賺取:",
		"burn_all": "全部銷毀",
		"burn_some": "部分銷毀",
		"register": "註冊",
		"burn_tokens": "銷毀代幣",
		"earn_tokens": "賺取代幣",
		"show_this_to_the_provider": "將此顯示給商家以獲得信用 (或必要時取消)。",
		"you_are_about_to_burn": "您即將銷毀所有的 <strong>{thisTokens}</strong> 代幣。",
		"you_have_no_tokens": "您沒有可銷毀的代幣。",
		"you_have_available_tokens": "您有 <strong>{availableTokens}</strong> 可銷毀的代幣。",
		"we_are_sorry": "很抱歉。您目前還沒有可銷毀的代幣。",
		"token_burn": "代幣銷毀",
		"tokens_to_burn": "要銷毁的代幣",
		"burn_more": "銷毁更多",
		"burn_less": "銷毁更少",
		"congratulations_you_burned": "恭喜您。您成功銷毀了 {tokens} {midSentence} 代幣 (ID: {id})。",
		"well_done_you_just_earned": "做得好。您剛剛賺取了 {t} {midSentence} 代幣 (ID: {id}).",
		"total_equals": "總共 = {tokens}",
		"going_to_token_registration": "前往代幣註冊中...",
		"earn_event_tokens_warning": "賺取事件代幣 - 警告",
		"sorry_you_already_earned": "抱歉。您已經賺取了 {eventName} 的代幣。",
		"event": "事件",
		"something_went_wrong_15_second": "出現問題導致15秒延遲。",
		"connectiontype": "{connectionType}",
		"connectionType": {
			"Reward": "獎勵",
			"Campaign": "活動"
		}
	},
	"theme": {
		"preview": "預覽",
		"confirm": "確認",
		"confirm_to_proceed": "確認繼續",
		"apply": "使用",
		"revert": "恢復",
		"default": "預設值",
		"apply_settings": "套用設定",
		"save_and_apply_theme_settings": "儲存並套用主題設定?",
		"restore_default": "恢復預設值",
		"restore_to_default_tqr_theme": "恢復預設 TQR 主題?"
	}
}