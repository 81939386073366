import Vue from "vue";
import VueI18n from "vue-i18n";
import de from "@/locales/de.ts";
import enUS from "@/locales/en-us.ts";
import th from "@/locales/th.ts";
import zhS from "@/locales/zh-s.ts";
import zhT from "@/locales/zh-t.ts";

Vue.use(VueI18n);

const messages = {
  "en-us": enUS,
	"de": de,
	"th": th,
  "zh-s": zhS,
	"zh-t": zhT,
};

const i18n = new VueI18n({
  locale: "en-us",
  fallbackLocale: "en-us",
  messages,
});

export default i18n;